<template>
  <div v-if="loading">
    <v-skeleton-loader v-for="i in 3" :key="i" type="list-item-avatar-three-line" />
  </div>
  <v-card v-else class="elevation-x">
    <v-list v-if="items.length" three-line>
      <v-list-item v-for="uid in items" :key="uid">
        <user-list-item :user="invitationGuest(uid)">
          <template v-slot:actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon
                       :disabled="canceling.includes(uid)"
                       :loading="canceling.includes(uid)"
                       v-on="on" @click.stop="cancel(uid)"
                >
                  <v-icon color="error">$close</v-icon>
                </v-btn>
              </template>
              <span>Annuler l'invitation</span>
            </v-tooltip>
          </template>
        </user-list-item>
        <v-divider v-if="uid !== items[items.length - 1]" class="my-0" />
      </v-list-item>
    </v-list>
    <empty-list v-else title="Aucun résultat"
                subtitle="Vous n'avez aucune invitation en attente" actions="Inviter un utilisateur" @action-empty="inviteUser"
    />
  </v-card>
</template>

<script>
  import {Invitation, Person} from '@/models'
  import UserListItem from '@/modules/core/layout/UserListItem'
  import EmptyList from '@/modules/core/layout/EmptyList'
  import {cancel as cancelInvitation} from '@/modules/invitations/api'
  import {removeItem} from '@/utils/array'

  export default {
    name: 'InvitationsList',
    components: {EmptyList, UserListItem},
    props: {
      items: Array,
      loading: Boolean
    },
    data() {
      return {
        canceling: []
      }
    },
    computed: {
      invitationGuest() {
        return uid => Person.get(Invitation.get(uid).guest)
      }
    },
    methods: {
      async cancel(uid) {
        try {
          this.canceling.push(uid)
          await cancelInvitation(uid)
          Invitation.delete(uid)
          this.$emit('canceled', uid)
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          removeItem(this.canceling, uid)
        }
      },
      inviteUser() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/spaces/components/SpaceProfessionalInvitationModal')
        })
      }
    }
  }
</script>

<style scoped>

</style>
