<template>
  <invitations-list :items="invitationsUid" :loading="loading" @canceled="removeInvitation" />
</template>

<script>
  import {column, removeItem} from '@/utils/array'
  import {Invitation, Person, Space} from '@/models'
  import {listPending as listPendingSpaceInvitations} from '@/modules/spaces/api/invitations'
  import InvitationsList from '@/modules/invitations/components/InvitationsList'

  export default {
    name: 'SpaceInvitationsList',
    components: {InvitationsList},
    data() {
      return {
        loading: true,
        cancelingInvitationsUid: [],
        invitationsUid: []
      }
    },
    computed: {
      invitation() {
        return uid => Invitation.get(uid)
      },
      user() {
        return uid => Person.get(uid)
      },
      space() {
        return Space.get(this.$store.state.core.space)
      }
    },
    async created() {
      try {
        let {data} = await listPendingSpaceInvitations(this.$store.state.core.space)
        Invitation.save(data)
        this.invitationsUid = column(data, 'uid')
      } catch (e) {
        this.$handleApiError(e)
      } finally {
        this.loading = false
      }

      this.$root.$on('space:invite', this.addInvitation)
    },
    beforeDestroy() {
      this.$root.$off('space:invite', this.addInvitation)
    },
    methods: {
      removeInvitation(uid) {
        removeItem(this.invitationsUid, uid)
        this.space.$mutate(space => {
          --space.total_guests
        })
      },
      addInvitation(uid) {
        if(!this.invitationsUid.includes(uid)) {
          this.invitationsUid.push(uid)
        }
      }
    }
  }
</script>
